import React, { useState } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { Popin } from '../../types/api';

type Props = Partial<ReactModalProps> & {
    ContentElement: React.ElementType;
    popin: Popin;
};

export type PopinProps = {
    popin: Popin;
};

export type PopinContentProps = PopinProps & {
    onClose?: () => void;
};

const PopinComponent = ({ ContentElement, popin, ...otherProps }: Props) => {
    const storageKey = `popin-${popin.id}`;
    const defaultIsOpen = popin.oncePerSession ? window.sessionStorage.getItem(storageKey) !== 'hidden' : true;

    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const onClose = () => {
        setIsOpen(false);

        if (popin.oncePerSession) {
            window.sessionStorage.setItem(storageKey, 'hidden');
        }
    };

    return (
        <ReactModal {...otherProps} className="c-checkout-popin" isOpen={isOpen}>
            <ContentElement popin={popin} onClose={onClose} />
        </ReactModal>
    );
};

export default PopinComponent;
