import {
    getCountryName,
    getGlobalECountryCookie,
    GlobalECountryData,
    setGlobalECountryCookie,
} from '@sezane/front-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useUrl } from '../hooks/useUrl';

export type Country = GlobalECountryData & {
    label: string;
};

type CountryContextType = {
    country: Country;
    setCountry: (country: GlobalECountryData) => void;
};

interface CountryProviderProps {
    initialData?: {
        country: GlobalECountryData;
    };
}

const CountryContext = React.createContext<CountryContextType>({
    country: {
        label: '',
    },
} as CountryContextType);

export const CountryProvider: React.FC<CountryProviderProps> = ({ children, initialData }) => {
    const { urls } = useUrl();
    const intl = useIntl();
    const [globalECountry, setGlobalECountryState] = useState(
        initialData ? initialData.country : getGlobalECountryCookie()
    );
    const country: Country = useMemo(
        () => ({
            ...globalECountry,
            label: getCountryName(globalECountry.countryISO || '', intl.locale),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [globalECountry]
    );
    const setCountry = (country: GlobalECountryData) => {
        setGlobalECountryCookie(country);
        setGlobalECountryState(country);
    };

    useEffect(() => {
        const onFocus = () => setGlobalECountryState(getGlobalECountryCookie());
        window.addEventListener('focus', onFocus);
        return () => window.removeEventListener('focus', onFocus);
    }, []);

    if (!globalECountry.countryISO) {
        if (process.env.NODE_ENV === 'production') {
            // redirect to country selection page if no Global-e cookie detected
            window.location.href = urls.chooseCountry;
            return null;
        } else {
            // default to FR if country not set (development/test)
            setGlobalECountryCookie({ countryISO: 'FR', currencyCode: 'EUR' });
        }
    }

    return <CountryContext.Provider value={{ country, setCountry }}>{children}</CountryContext.Provider>;
};

export const useCountry = () => {
    const countryContext = useContext(CountryContext);
    return countryContext;
};
