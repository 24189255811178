import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import { FETCH_ORDER_KEY, useOrderContext } from '../../context/OrderContext';
import { useUserState } from '../../context/UserStateContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { useUrl } from '../../hooks/useUrl';
import { OrderProduct } from '../../types/api';
import useAnalytics from '../../utils/analytics';
import apiClient from '../../utils/api';
import { BRANDS } from '../../utils/brand';
import { getCroppedImageUrl } from '../../utils/cloudinary';
import ChangeQuantityFromBasket from '../Buttons/ChangeQuantityFromBasket';
import { MinusIcon, PlusIcon, WritingIcon } from '../Icons';
import DeleteOrderItem from './DeleteOrderItem';
import OrderItemMultiProduct from './OrderItemMultiProduct';

type Props = {
    display?: 'small' | 'normal';
    product: OrderProduct;
    setCustomisationProduct?: (product: OrderProduct | undefined) => void;
};

const OrderItem = ({ display = 'normal', product, setCustomisationProduct }: Props) => {
    const queryClient = useQueryClient();
    const { brand, orderId, site } = useAppContext();
    const { authenticated } = useAuth();
    const { userState } = useUserState();
    const { urls } = useUrl();
    const { order } = useOrderContext();
    const { onRemoveProduct, onQuantityAdded, onQuantityRemoved } = useAnalytics();

    const [removeOverlay, setRemoveOverlay] = useState(false);

    const checkIfInWishlist = () =>
        userState?.wishlist?.eans?.find(ean => ean === product.productProperty?.selectedVariant?.selectedItem?.ean);

    const hasCustomisation = product.customisation?.rows && product.customisation.rows.length > 0;
    const productUrl = `${urls.ecommerceRoot}${product.productUrl}`;
    const shouldDisplayOverlayOnRemove =
        authenticated &&
        brand !== BRANDS.octobre &&
        !checkIfInWishlist() &&
        !product.multiProducts?.length &&
        !product.ecardProperty;
    const removeProduct = useMutationWithError(
        () =>
            apiClient.updateProductOnOrder(
                orderId,
                product.orderItemProductId || product.multiProducts?.[0].orderItemProductId!,
                {
                    site: site.code!,
                    brand,
                },
                { quantity: 0 }
            ),
        {
            onSuccess: data => {
                onRemoveProduct(order!, product);
                queryClient.setQueryData(FETCH_ORDER_KEY, data);
                setRemoveOverlay(false);
            },
            onError: () => {
                setRemoveOverlay(false);
            },
        }
    );

    const onRemove = () => {
        shouldDisplayOverlayOnRemove ? setRemoveOverlay(true) : removeProduct.mutate();
    };
    const shouldUpdateProductQuantity = (quantity: number): boolean => {
        if (quantity === 0 && shouldDisplayOverlayOnRemove) {
            setRemoveOverlay(true);
            return false;
        }

        return true;
    };

    if (product.multiProducts && product.multiProducts?.length > 0) {
        return (
            <OrderItemMultiProduct product={product} display={display} productUrl={productUrl} onRemove={onRemove} />
        );
    }

    return (
        <div className="c-order-checkout__item">
            <div className="o-grid o-grid--gutter-sm-bis">
                <div className="o-grid__col o-grid__col--7@sm o-grid__col--4@md o-grid__col--4@md-plus">
                    <div className="c-order-checkout__image">
                        {product.gift ? (
                            <img
                                src={getCroppedImageUrl(product.imageUrl!, 200)}
                                width="122"
                                height="178"
                                className="u-w-100"
                                alt={product.label}
                            />
                        ) : (
                            <a href={productUrl} target="_blank" rel="noreferrer">
                                <img
                                    src={getCroppedImageUrl(product.imageUrl!, 200)}
                                    width="122"
                                    height="178"
                                    className="u-w-100"
                                    alt={product.label}
                                />
                            </a>
                        )}
                    </div>
                </div>
                <div className="o-grid__col o-grid__col--12 o-grid__col--15@md o-grid__col--16@md-plus">
                    {product.gift ? (
                        <span className="c-order-checkout__label">{product.label}</span>
                    ) : (
                        <a className="c-order-checkout__label" href={productUrl} target="_blank" rel="noreferrer">
                            {product.label}
                        </a>
                    )}
                    {product.colorLabel && (
                        <ul className="c-order-checkout__list c-order-checkout__list--secondary u-mt-n">
                            <li>{product.colorLabel}</li>
                        </ul>
                    )}
                    <ul className="c-order-checkout__list u-mt-xs">
                        {product.sizeLabel && (!product.gift || product.sizeLabel !== 'TU') && (
                            <li>
                                <FormattedMessage id="cart.list.size" /> {product.sizeLabel}
                                {display === 'small' && ` x ${product.quantity}`}
                            </li>
                        )}
                        {!product.gift && display === 'normal' && (
                            <li className="u-flex u-flex-wrap">
                                <FormattedMessage id="cart.list.quantity" />
                                <div className="c-quantity-button u-flex-self-center">
                                    {!product.isCustomizable && !product.ecardProperty && !product.gift && (
                                        <ChangeQuantityFromBasket
                                            product={product}
                                            className="c-quantity-button__action c-quantity-button__action--minus"
                                            quantity={product.quantity! - 1}
                                            shouldUpdateProductQuantity={shouldUpdateProductQuantity}
                                            onChange={order => onQuantityRemoved(order, product)}
                                        >
                                            <i className="c-icon u-text-icon-xxs">
                                                <MinusIcon />
                                            </i>
                                            <span className="u-visually-hidden">
                                                <FormattedMessage id="actions.remove_quantity" />
                                            </span>
                                        </ChangeQuantityFromBasket>
                                    )}
                                    <input
                                        type="number"
                                        name="quantity"
                                        min="0"
                                        className="c-quantity-button__value"
                                        value={product.quantity}
                                        onChange={() => {}}
                                        disabled={true}
                                    />
                                    {!product.isCustomizable && !product.ecardProperty && !product.gift && (
                                        <ChangeQuantityFromBasket
                                            product={product}
                                            className="c-quantity-button__action c-quantity-button__action--plus"
                                            quantity={product.quantity! + 1}
                                            shouldUpdateProductQuantity={shouldUpdateProductQuantity}
                                            onChange={order => onQuantityAdded(order, product)}
                                        >
                                            <i id="actions.add_quantity" className="c-icon u-text-icon-xxs">
                                                <PlusIcon />
                                            </i>
                                            <span className="u-visually-hidden">
                                                <FormattedMessage id="actions.add_quantity" />
                                            </span>
                                        </ChangeQuantityFromBasket>
                                    )}
                                </div>
                            </li>
                        )}

                        {!product.gift && (
                            <li>
                                <FormattedMessage id="cart.list.price" /> {product.unitDisplayPrice}
                            </li>
                        )}

                        {product.ecardProperty && (
                            <li>
                                <FormattedMessage id="cart.list.ecard.receiver_email" />{' '}
                                {product.ecardProperty.receiverEmail}
                            </li>
                        )}

                        {product.productProperty?.selectedVariant?.preorder &&
                            product.productProperty?.selectedVariant?.preorderDate &&
                            !product.gift && (
                                <li>
                                    <FormattedMessage
                                        id="cart.list.preorder"
                                        values={{
                                            date: product.productProperty?.selectedVariant?.preorderDate,
                                        }}
                                    />
                                </li>
                            )}
                    </ul>

                    {product.customisation?.service && !product.gift && (
                        <p className="c-order-checkout__customization">
                            {hasCustomisation && <FormattedMessage id="cart.list.customisation" />}
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setCustomisationProduct && setCustomisationProduct(product);
                                }}
                            >
                                {hasCustomisation ? (
                                    <span style={{ textTransform: 'none' }}>
                                        “{product.customisation.rows?.join('/')}”
                                    </span>
                                ) : (
                                    <FormattedMessage id="cart.list.empty_customisation" />
                                )}
                                <i className="c-icon u-text-icon-xxs u-ml-xs">
                                    <WritingIcon />
                                </i>
                            </span>
                        </p>
                    )}
                    {display === 'normal' && !product.gift && (
                        <div className="u-none@md u-none@md-plus u-mt-xs-sm">
                            <button className={'c-order-checkout__button'} type="button" onClick={onRemove}>
                                <FormattedMessage id="actions.remove_from_basket" />
                            </button>
                        </div>
                    )}
                </div>
                <div className="o-grid__col o-grid__col--5 o-grid__col--4@md-plus u-text-right">
                    <p className="c-order-checkout__price">
                        {product.crossedOutPrice ? (
                            <>
                                <s className="u-color-gray-400">{product.totalCrossedOutDisplayPrice}</s>{' '}
                            </>
                        ) : null}
                        {product.gift ? <FormattedMessage id="cart.promo.free" /> : product.totalDisplayPrice}
                    </p>
                    {display === 'normal' && !product.gift && (
                        <div className="u-none@sm">
                            <button className={'c-order-checkout__button'} type="button" onClick={onRemove}>
                                <FormattedMessage id="actions.remove_from_basket" />
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {removeOverlay && (
                <DeleteOrderItem
                    product={product}
                    onIgnore={() => setRemoveOverlay(false)}
                    removeProduct={() => removeProduct.mutate()}
                />
            )}
        </div>
    );
};

export default OrderItem;
