import { Disclosure } from '@headlessui/react';
import classnames from 'classnames';
import { FC, ReactNode } from 'react';

interface ToggleProps {
    className?: string;
    defaultOpen?: boolean;
    label: ReactNode;
    onToggle?: (open: boolean) => void;
}

const Toggle: FC<ToggleProps> = ({ children, className, defaultOpen = false, label, onToggle }) => {
    return (
        <div className={classnames('c-disclosure', className)}>
            <Disclosure defaultOpen={defaultOpen}>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="c-disclosure__trigger" onClickCapture={() => onToggle?.(!open)}>
                            {label}
                        </Disclosure.Button>
                        <Disclosure.Panel className="c-disclosure__panel">{open ? children : null}</Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

export default Toggle;
