import { memo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppContext } from '../../context/AppContext';
import { Order, OrderProduct } from '../../types/api';
import useAnalytics from '../../utils/analytics';
import { hasNormalDeliveryProducts, hasQuickDeliveryProducts } from '../../utils/order';
import Timer from '../Timer/Timer';
import GiftItem from './GiftItem';
import OrderItem from './OrderItem';
import GiftIcon from '../Icons/Gift';

interface OrderProps {
    order: Order;
    setCustomisationProduct?: (product: OrderProduct | undefined) => void;
    setEditGiftMessage: () => void;
}

const OrderComponent = ({ order, setCustomisationProduct, setEditGiftMessage }: OrderProps) => {
    const { expireAt, giftMessage, products = [] } = order;
    const { isGlobalE } = useAppContext();
    const { onDisplayCart } = useAnalytics();
    const quickDeliveryProducts = products.filter(hasQuickDeliveryProducts);
    const normalDeliveryProducts = products.filter(hasNormalDeliveryProducts);
    const giftDeliveryProducts = products.filter(({ gift }) => gift);

    useEffect(() => {
        if (products && products?.length > 0) {
            onDisplayCart(order);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="c-order-checkout">
            <div className="c-order-checkout__header u-none@md u-none@md-plus">
                {!isGlobalE && expireAt && <Timer className="u-text-center" expireAt={expireAt} />}
            </div>
            {quickDeliveryProducts.length > 0 && (
                <>
                    <div className="c-order-checkout__header">
                        <div className="o-grid o-grid--gutter-sm u-flex-cross-baseline">
                            <div className="o-grid__col">
                                <p className="c-order-checkout__title">
                                    <FormattedMessage id="cart.list.quick_delivery" />
                                </p>
                            </div>
                            <div className="o-grid__col u-ml-auto u-none@sm">
                                {!isGlobalE && expireAt && <Timer expireAt={expireAt} />}
                            </div>
                        </div>
                    </div>
                    <div className="c-order-checkout__body">
                        {quickDeliveryProducts.map(orderProduct => (
                            <OrderItem key={orderProduct.orderItemProductId} product={orderProduct} />
                        ))}
                    </div>
                </>
            )}
            {normalDeliveryProducts.length > 0 && (
                <>
                    <div className="c-order-checkout__header">
                        <div className="o-grid o-grid--gutter-sm u-flex-cross-baseline">
                            <div className="o-grid__col">
                                <p className="c-order-checkout__title">
                                    {quickDeliveryProducts.length > 0 ? (
                                        <FormattedMessage id="cart.list.standard_delivery" />
                                    ) : (
                                        <FormattedMessage id="cart.list.shipping" />
                                    )}
                                </p>
                            </div>
                            {!isGlobalE && !quickDeliveryProducts.length && expireAt && (
                                <div className="o-grid__col u-ml-auto u-none@sm">
                                    <Timer expireAt={expireAt} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="c-order-checkout__body">
                        {normalDeliveryProducts.map(orderProduct => (
                            <OrderItem
                                key={orderProduct.orderItemProductId}
                                product={orderProduct}
                                setCustomisationProduct={setCustomisationProduct}
                            />
                        ))}
                    </div>
                </>
            )}
            {giftDeliveryProducts.length > 0 && (
                <>
                    <div className="c-order-checkout__header">
                        <div className="o-grid o-grid--gutter-sm u-flex-cross-baseline">
                            <div className="o-grid__col">
                                <p className="c-order-checkout__title">
                                    <span className="u-mr-sm">
                                        <GiftIcon />
                                    </span>
                                    <FormattedMessage id="cart.list.gifts" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="c-order-checkout__body">
                        {giftDeliveryProducts.map(orderProduct => (
                            <OrderItem
                                key={orderProduct.orderItemProductId}
                                product={orderProduct}
                                setCustomisationProduct={setCustomisationProduct}
                            />
                        ))}
                    </div>
                </>
            )}
            {giftMessage && (
                <div className="c-order-checkout__body">
                    <GiftItem message={giftMessage} onEdit={setEditGiftMessage} />
                </div>
            )}
        </div>
    );
};

export default memo(OrderComponent);
