import { useIntl } from 'react-intl';
import PopinComponent, { PopinContentProps, PopinProps } from './Popin';
import { CSSProperties } from 'react';

export const PopinContent = ({ onClose, popin }: PopinContentProps) => {
    const { formatMessage } = useIntl();
    const colorPrimary = popin.colorPrimary;
    const colorSecondary = popin.colorSecondary;
  
    const bannerStyle: CSSProperties = {
      ['--banner-background-color' as any]: colorPrimary,
      ['--banner-inner-background-color' as any]: colorPrimary,
      ['--banner-inner-color' as any]: colorSecondary
    };

    return (
        <div
            className="c-banner c-banner--top"
            aria-hidden="false"
            style={bannerStyle}
        >
            <div className="c-banner__inner">
                <div className="o-container">
                    <div className="o-grid o-grid--nowrap">
                        <div className="o-grid__col o-grid__col--offset-1 o-grid__col--22">
                            <div className="c-banner__box u-flex u-flex-cross-center">
                                <div className="s-cms-banner u-w-100">
                                    <div
                                        className="s-wysiwyg u-line-clamp-1 u-line-clamp-3@sm u-line-clamp-4@xs"
                                        dangerouslySetInnerHTML={{ __html: popin.content || '' }}
                                    />
                                </div>
                                <button
                                    type="button"
                                    aria-label={formatMessage({ id: 'actions.close' })}
                                    className="c-banner__close"
                                    onClick={onClose}
                                >
                                    <i className="c-icon">
                                        <svg
                                            width="22"
                                            height="22"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 53.34 53.34"
                                            className="c-icon__svg"
                                            aria-hidden="true"
                                        >
                                            <path d="M53.34 7.64L45.7 0 26.67 19.03 7.64 0 0 7.64l19.03 19.03L0 45.7l7.64 7.64 19.03-19.03L45.7 53.34l7.64-7.64-19.03-19.03L53.34 7.64z" />
                                        </svg>
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PopinBanner = (props: PopinProps) => (
    <PopinComponent
        {...props}
        ContentElement={PopinContent}
        parentSelector={() => document.querySelector('#popinBannerContainer')!}
        overlayClassName="c-checkout-popin--banner"
        bodyOpenClassName="is-react-banner-show"
    />
);

export default PopinBanner;
