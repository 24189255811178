import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';

import { useAppContext } from '../../context/AppContext';
import { Country, useCountry } from '../../context/CountryContext';
import { Routes, useUrl } from '../../hooks/useUrl';
import apiClient from '../../utils/api';
import PopinBanner from './PopinBanner';
import PopinClassic from './PopinClassic';

const POPIN_PAGES_VALUES: Record<keyof Routes, keyof Routes> = {
    cart: 'cart',
    shipping: 'shipping',
    payment: 'payment',
    globalE: 'globalE',
    paymentSuccess: 'paymentSuccess',
};

export const checkPage = (popinPages: Array<keyof Routes> = [], routes: Routes, pathname: string) => {
    return (Object.keys(POPIN_PAGES_VALUES) as Array<keyof typeof POPIN_PAGES_VALUES>).some(page => {
        return pathname === routes[page] && popinPages.includes(POPIN_PAGES_VALUES[page]);
    });
};

export const checkCountry = (popinCountries: string[] = [], country: Country) => {
    if (popinCountries.length === 0) {
        return true;
    } else if (country.countryISO) {
        return popinCountries.includes(country.countryISO);
    }
    return false;
};

const Popins = () => {
    const { brand, site } = useAppContext();
    const { country } = useCountry();
    const { pathname } = useLocation();
    const { routes } = useUrl();
    const intl = useIntl();

    const { data } = useQuery('popins', () =>
        apiClient.getPopins({
            site: site.code!,
            brand,
            locale: intl.locale,
        })
    );

    const popins = data?.data || [];

    return (
        <>
            {popins.map(popin => {
                if (
                    !checkPage(popin.pages as Array<keyof Routes>, routes, pathname) ||
                    !checkCountry(popin.countries, country)
                )
                    return null;

                switch (popin.format) {
                    case 'banner':
                        return <PopinBanner key={popin.id} popin={popin} />;
                    case 'popin':
                        return <PopinClassic key={popin.id} popin={popin} />;
                    default:
                        return null;
                }
            })}
        </>
    );
};

export default Popins;
