import { withErrorBoundary } from 'react-error-boundary';
import { Order, OrderProduct } from '../../types/api';
import ErrorFallback from '../Errors/ErrorFallback';
import CustomisationModal from './Customisation/Customisation';
import OptionsModal from './Options';

interface Props {
    displayOptionsModal: boolean;
    setDisplayOptionsModal: (displayOptionsModal: boolean) => void;
    customisationProduct: OrderProduct | undefined;
    setCustomisationProduct: (customisationProduct: OrderProduct | undefined) => void;
    order?: Order;
    onConfirmDisplayOptions?: () => void;
}

const CommonRouteModals = ({
    displayOptionsModal,
    setDisplayOptionsModal,
    customisationProduct,
    setCustomisationProduct,
    order,
    onConfirmDisplayOptions,
}: Props) => (
    <>
        {customisationProduct && (
            <CustomisationModal
                product={customisationProduct}
                onRequestClose={() => setCustomisationProduct(undefined)}
            />
        )}

        {displayOptionsModal && order && (
            <OptionsModal
                onConfirm={onConfirmDisplayOptions}
                onRequestClose={() => setDisplayOptionsModal(false)}
                products={order.packaging}
            />
        )}
    </>
);

export default withErrorBoundary(CommonRouteModals, { FallbackComponent: ErrorFallback });
