import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import { getCroppedImageUrl } from '../../utils/cloudinary';
import PopinComponent, { PopinContentProps, PopinProps } from './Popin';

export const PopinContent = ({ onClose, popin }: PopinContentProps) => {
    const { formatMessage } = useIntl();
    const colorPrimary = popin.colorPrimary;
    const colorSecondary = popin.colorSecondary;
  
    const dialogStyle: CSSProperties = {
      ['--dialog-background-color' as any]: colorPrimary,
      color: colorSecondary,
    };

    return (
        <div className="c-dialog c-dialog--modeless c-dialog--popin c-page__dialog u-none@sm" style={dialogStyle} aria-hidden="false">
            <div className="c-dialog__inner">
                <button
                    type="button"
                    aria-label={formatMessage({ id: 'actions.close' })}
                    className="c-dialog__close u-btn-unstyled"
                    onClick={onClose}
                >
                    <i className="c-icon c-dialog__icon" style={{ color: popin.closeCtaColor }}>
                        <svg
                            width="22"
                            height="22"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 53.34 53.34"
                            className="c-icon__svg"
                            aria-hidden="true"
                        >
                            <path d="M53.34 7.64L45.7 0 26.67 19.03 7.64 0 0 7.64l19.03 19.03L0 45.7l7.64 7.64 19.03-19.03L45.7 53.34l7.64-7.64-19.03-19.03L53.34 7.64z" />
                        </svg>
                    </i>
                </button>

                {popin.mediaType === 'image' && popin.mediaUrl && (
                    <div className="u-flex u-flex-column">
                        <img
                            src={getCroppedImageUrl(popin.mediaUrl, 340)}
                            width="340"
                            height="276"
                            className="u-flex-item-auto u-cover"
                            alt={popin.title}
                            loading="lazy"
                        />
                    </div>
                )}
                {popin.mediaType === 'video' && popin.mediaUrl && (
                    <div className="u-flex u-flex-column">
                        <video width="340" className="u-flex-item-auto u-cover" muted autoPlay loop playsInline>
                            <source src={`${popin.mediaUrl}.mp4`} type="video/mp4" />
                            <source src={`${popin.mediaUrl}.webm`} type="video/webm" />
                        </video>
                    </div>
                )}
                {popin.mediaType === 'svg' && popin.mediaUrl && (
                    <div className="u-flex u-flex-main-center u-pt-md u-pl-md u-pr-md">
                        <img src={`/build/images/logo-popin/${popin.mediaUrl}`} alt={popin.title} loading="lazy" />
                    </div>
                )}

                <div className="c-dialog__box">
                    <h4 className="u-h5 u-text-uppercase">{popin.title}</h4>
                    <div className="u-p s-wysiwyg" dangerouslySetInnerHTML={{ __html: popin.content || '' }} />
                </div>
            </div>
        </div>
    );
};

const PopinClassic = (props: PopinProps) => (
    <PopinComponent {...props} ContentElement={PopinContent} overlayClassName="c-checkout-popin--classic" />
);

export default PopinClassic;
