import { FC, ReactNode } from 'react';
import { TooltipIcon } from '../Icons';
import classnames from 'classnames';

interface TooltipProps {
    id: string;
    className?: string;
    iconClassName?: string;
    bubbleClassName?: string;
    label?: ReactNode;
    content: ReactNode;
    icon?: ReactNode;
}

const Tooltip: FC<TooltipProps> = ({ id, className, iconClassName, bubbleClassName, label, content, icon }) => (
    <div className={classnames('c-tooltip c-tooltip--tertiary', className)}>
        {label}
        {icon ? (
            icon
        ) : (
            <i className={classnames('c-icon c-tooltip__trigger u-text-icon-xs', iconClassName)} aria-describedby={id}>
                <TooltipIcon />
            </i>
        )}

        <div id={id} className={classnames('c-tooltip__bubble', bubbleClassName)} role="tooltip">
            {content}
        </div>
    </div>
);

export default Tooltip;
