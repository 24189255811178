import { Listbox } from '@headlessui/react';
import classnames from 'classnames';
import { memo, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChevronIcon } from '../Icons';

interface DropdownProps<ItemType> {
    className?: string;
    defaultLabel?: ReactNode;
    disabled?: boolean;
    id?: string;
    itemKey?: string;
    items: ItemType[];
    onChange: (item: ItemType) => void;
    renderItem: (item: ItemType) => ReactNode;
    value?: ItemType;
    selectUniqueItem?: boolean;
}

const Dropdown = <ItemType extends Record<string, any>>({
    className,
    defaultLabel,
    disabled,
    id,
    itemKey = 'id',
    items,
    onChange,
    renderItem,
    value,
    selectUniqueItem = false, // Disable dropdown options if only one item is provided
}: DropdownProps<ItemType>) => {
    return (
        <Listbox
            name={id}
            value={value ? String(value[itemKey]) : ''}
            onChange={(newValue: string) => {
                const selectedItem = items.find(item => item[itemKey].toString() === newValue);
                if (selectedItem) {
                    onChange(selectedItem);
                }
            }}
            disabled={disabled || (value && selectUniqueItem)}
        >
            {({ open }) => (
                <div
                    id={id}
                    className={classnames('c-dropdown c-dropdown--outline c-dropdown--visible', className, {
                        'c-dropdown--disabled': disabled,
                    })}
                >
                    <Listbox.Button className="c-dropdown__btn c-dropdown__btn--lowercase">
                        {value ? (
                            <span key="value" className="c-dropdown__container">
                                {renderItem(value)}
                            </span>
                        ) : (
                            <span key="default-label">
                                {defaultLabel || <FormattedMessage id="forms.input.dropdown_default_label" />}
                            </span>
                        )}

                        {selectUniqueItem && value ? null : (
                            <i className="c-icon c-dropdown__icon">
                                <ChevronIcon />
                            </i>
                        )}
                    </Listbox.Button>
                    <div className="c-dropdown__list" hidden={!open}>
                        <Listbox.Options className="u-list-unstyled">
                            {items.map(item => (
                                <Listbox.Option
                                    className="c-dropdown__item"
                                    key={item[itemKey]}
                                    value={item[itemKey].toString()}
                                >
                                    <div className="c-dropdown__link">
                                        <span className="c-dropdown__form">
                                            <span className="c-dropdown__label c-dropdown__container">
                                                {renderItem(item)}
                                            </span>
                                        </span>
                                    </div>
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </div>
                </div>
            )}
        </Listbox>
    );
};

export default memo(Dropdown) as typeof Dropdown;
