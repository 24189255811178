import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Toggle from '../Toggle/Toggle';
import DiscountForm from './DiscountForm';

interface DiscountProps {
    hasCheckbox?: boolean;
}
const Discount: FC<DiscountProps> = ({ hasCheckbox }) =>
    hasCheckbox ? (
        <Toggle
            label={
                <label className="c-label c-label--secondary c-label--custom c-label--checkbox">
                    <span className="c-disclosure__title u-text-uppercase">
                        <FormattedMessage id="cart.discount" />
                    </span>
                </label>
            }
        >
            <div className="c-disclosure__inner">
                <DiscountForm />
            </div>
        </Toggle>
    ) : (
        <DiscountForm />
    );

export default Discount;
