import { FC } from 'react';
import Loader, { LoaderProps } from './Loader';

interface LoaderWrapperProps {
    isLoading: boolean;
    loaderProps?: LoaderProps;
}

const LoaderWrapper: FC<LoaderWrapperProps> = ({ children, isLoading, loaderProps = {} }) => {
    return isLoading ? <Loader {...loaderProps} /> : <>{children}</>;
};

export default LoaderWrapper;
