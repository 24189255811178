import Loader from '../Loader/Loader';

type Props = React.ComponentProps<'button'> & {
    isLoading?: boolean;
};

const Button: React.FC<Props> = ({ children, isLoading, ...props }) => {
    return (
        <button
            {...props}
            disabled={isLoading || props.disabled}
            style={{ cursor: isLoading ? 'progress' : undefined }}
        >
            {isLoading ? <Loader color="white" size={18} /> : children}
        </button>
    );
};

export default Button;
