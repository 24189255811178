import classNames from 'classnames';
import { useState } from 'react';

import { PromotionalCampaign } from '../../types/api';
import scrollTo from '../../utils/scrollTo';
import AddToBasket from '../Buttons/AddToBasket';
import Dropdown from '../Dropdown/Dropdown';
import { getCroppedImageUrl } from '../../utils/cloudinary';
import { DEFAULT_IMG_PLACEHOLDER } from '../../utils/common';

interface PromoProps {
    className?: string;
    promo: PromotionalCampaign;
    onAdd?: () => void;
}

const Promo = ({ className, promo, onAdd }: PromoProps) => {
    const refProduct = promo.products && promo.products[0];
    const [selectedProduct, setSelectedProduct] = useState(refProduct);

    const isDisplayOnly = promo.displayOnly;
    const withChoice = promo.withChoice;

    return refProduct ? (
        <div className={classNames('c-box c-box--outline', className)}>
            <div className="c-box__inner">
                <div className="o-grid o-grid--gutter-sm">
                    {promo.products?.length === 1 && (
                        <div className="o-grid__col o-grid__col--24 o-grid__col--7@md-plus u-flex-item-last@md-plus">
                            <div className="c-ratio c-ratio--1-2 c-ratio--5-4@md-plus">
                                <div className="c-ratio__container">
                                    <div className="c-ratio__body">
                                        <img
                                            src={selectedProduct?.selectedVariant?.imageUrl}
                                            alt={`${refProduct.name} - ${selectedProduct?.selectedVariant?.name}`}
                                            className="u-w-100 u-cover"
                                            width="265"
                                            height="331"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className="o-grid__col o-grid__col--24 o-grid__col--17@md-plus u-flex u-flex-column"
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <h3 className="u-h4-bis u-text-uppercase">{promo.title}</h3>
                            <p
                                className="u-mt-xs-sm u-mt-sm@md u-mt-sm@md-plus"
                                dangerouslySetInnerHTML={{ __html: promo.description || '' }}
                            />
                        </div>
                    </div>
                </div>
                {isDisplayOnly &&
                    promo.products &&
                    promo.products.length > 1 &&
                    promo.products.map(product => (
                        <div className="c-box c-box--outline u-p-xxs u-flex c-order-checkout__promo--box">
                            <img
                                src={getCroppedImageUrl(
                                    selectedProduct?.selectedVariant?.imageUrl || DEFAULT_IMG_PLACEHOLDER,
                                    200
                                )}
                                alt={`${refProduct.name} - ${selectedProduct?.selectedVariant?.name}`}
                                width="53"
                                height="75"
                            />
                            <div className="u-ml-md u-mr-md u-flex">
                                {product.name} - {product.selectedVariant?.name} -{' '}
                                {product.selectedVariant?.selectedItem?.size}
                            </div>
                        </div>
                    ))}

                {!isDisplayOnly && (
                    <>
                        {promo.products && promo.products.length > 1 && (
                            <div className="u-mt-sm-bis">
                                {withChoice ? (
                                    <Dropdown
                                        className="c-dropdown--quaternary u-w-100"
                                        items={
                                            promo.products?.map((product, i) => ({
                                                ...product,
                                                uniqueKey: `${refProduct.name}-${i}`,
                                            })) || []
                                        }
                                        itemKey="uniqueKey"
                                        onChange={setSelectedProduct}
                                        selectUniqueItem={promo.products?.length === 1}
                                        renderItem={product => (
                                            <>
                                                <img
                                                    src={product.selectedVariant?.imageUrl}
                                                    alt={product.selectedVariant?.name || ''}
                                                    width="53"
                                                    height="75"
                                                    loading="lazy"
                                                />
                                                <span className="u-ml-md u-mr-md">
                                                    <span className="c-dropdown__text--multiline">
                                                        {product.selectedVariant?.name}
                                                        {' - '}
                                                        {product.selectedVariant?.selectedItem?.size}
                                                    </span>
                                                </span>
                                            </>
                                        )}
                                        value={selectedProduct}
                                    />
                                ) : (
                                    promo.products.map((product, index) => (
                                        <div
                                            key={product?.selectedVariant?.selectedItem?.hash}
                                            className={classNames(
                                                'c-dropdown c-dropdown--outline c-dropdown--visible c-dropdown--quaternary u-w-100',
                                                {
                                                    'u-mt-xs': index > 0,
                                                }
                                            )}
                                        >
                                            <div className="c-dropdown__btn c-dropdown__btn--lowercase">
                                                <img
                                                    src={product.selectedVariant?.imageUrl}
                                                    alt={product.selectedVariant?.name || ''}
                                                    width="53"
                                                    height="75"
                                                    loading="lazy"
                                                />
                                                <span className="u-ml-md u-flex-item-fluid">
                                                    {product.name}
                                                    {' - '}
                                                    {product.selectedVariant?.name}
                                                    {' - '}
                                                    {product.selectedVariant?.selectedItem?.size}
                                                </span>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                        <AddToBasket
                            className="c-btn c-btn--loader u-w-100 u-mt-sm-bis"
                            ean={
                                withChoice
                                    ? selectedProduct?.selectedVariant?.selectedItem?.ean!
                                    : (promo.products || []).map(product => product.selectedVariant?.selectedItem?.ean!)
                            }
                            campaignId={promo.id}
                            onAdd={() => {
                                scrollTo('#order');
                                onAdd && onAdd();
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    ) : null;
};

export default Promo;
