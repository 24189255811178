import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';

import { useAppContext } from '../../context/AppContext';
import { useAuth } from '../../context/AuthContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import apiClient from '../../utils/api';
import { FETCH_WISHLIST_KEY } from '../Wishlist/Wishlist';
import Button from './Button';

type Props = {
    ean: string;
    className?: string;
    onAdd?: () => void;
};

const AddToWishlist: FC<Props> = ({
    ean,
    className,
    onAdd,
    children = <FormattedMessage id="actions.add_to_wishlist" />,
}) => {
    const { brand, orderId, site } = useAppContext();
    const { keycloakId } = useAuth();
    const queryClient = useQueryClient();

    const addProduct = useMutationWithError(
        () =>
            apiClient.addWishlistItem(
                keycloakId,
                'unknown', // this param is not used by the API
                ean,
                {
                    site: site.code!,
                    brand,
                }
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(FETCH_WISHLIST_KEY);
                onAdd && onAdd();
            },
        }
    );

    if (!orderId) {
        return null;
    }

    return (
        <Button
            type="button"
            className={className ? className : 'c-btn c-btn--close c-btn--loader'}
            onClick={() => addProduct.mutate()}
            isLoading={addProduct.isLoading}
        >
            {children}
        </Button>
    );
};

export default AddToWishlist;
