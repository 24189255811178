import { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import Router from './Router';
import Loader from './components/Loader/Loader';
import { AppContextProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';
import { CountryProvider } from './context/CountryContext';
import { GeolocProvider } from './context/GeolocContext';
import { GlobalEProvider } from './context/GlobalEContext';
import OrderContextProvider from './context/OrderContext';
import TranslationProvider from './context/TranslationProvider';
import { WorkflowProvider } from './context/WorkflowContext';
import { getBrand } from './utils/brand';
import { logAppVersion } from './utils/logs';

import '@sezane/front-components/dist/index.css';
import 'sezane-front-css/main.scss';

import { UserStateProvider } from './context/UserStateContext';
import './index.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

logAppVersion();

function App() {
    const brand = getBrand();

    useEffect(() => {
        document.documentElement.classList.add('c-document', `t-${brand}`);
        document.body.classList.add('c-page');

        return () => {
            document.documentElement.classList.remove('c-document', `t-${brand}`);
            document.body.classList.remove('c-page');
        };
    }, [brand]);

    return (
        <Suspense fallback={<Loader />}>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider brand={brand}>
                    <AuthProvider>
                        <UserStateProvider>
                            <TranslationProvider>
                                <CountryProvider>
                                    <OrderContextProvider>
                                        <WorkflowProvider>
                                            <GlobalEProvider>
                                                <GeolocProvider>
                                                    <Router />
                                                </GeolocProvider>
                                            </GlobalEProvider>
                                        </WorkflowProvider>
                                    </OrderContextProvider>
                                </CountryProvider>
                            </TranslationProvider>
                        </UserStateProvider>
                    </AuthProvider>
                </AppContextProvider>
            </QueryClientProvider>
        </Suspense>
    );
}

export default App;
