import { shouldPolyfill } from '@formatjs/intl-displaynames/should-polyfill';

async function polyfill() {
    if (!shouldPolyfill()) {
        return;
    }

    await Promise.all([
        import('@formatjs/intl-locale/polyfill'),
        import('@formatjs/intl-displaynames/polyfill'),
        import('@formatjs/intl-displaynames/locale-data/de'),
        import('@formatjs/intl-displaynames/locale-data/en'),
        import('@formatjs/intl-displaynames/locale-data/es'),
        import('@formatjs/intl-displaynames/locale-data/fr'),
        import('@formatjs/intl-displaynames/locale-data/it'),
        import('@formatjs/intl-displaynames/locale-data/pt'),
    ]);
}

export default polyfill;
