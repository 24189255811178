const TooltipIcon = () => (
    <svg
        width="19"
        height="19"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 19"
        className="c-icon__svg"
        aria-hidden="true"
    >
        <path d="M8.80273 5.55859c0-.16797.0586-.3125.17579-.43359.12109-.12109.26562-.18164.43359-.18164.16797 0 .31055.06055.42773.18164.11719.12109.17576.26562.17576.43359 0 .17188-.05857.31836-.17576.43946-.11718.12109-.25976.18164-.42773.18164s-.3125-.06055-.43359-.18164c-.11719-.1211-.17579-.26758-.17579-.43946zm1.13086 2.44922v3.34569c0 .5117.00782.834.02344.9668.01563.1328.07227.2344.16997.3047.0976.0703.2714.1055.5214.1055h.1231V13h-2.584v-.2695h.19922c.27734 0 .45703-.0391.53906-.1172.08594-.0821.13477-.1778.14649-.2871.01562-.1133.02343-.4375.02343-.9727V9.90039c0-.5625-.02734-.90625-.08203-1.03125-.05078-.125-.12109-.20312-.21094-.23437-.08593-.03125-.23828-.04688-.45703-.04688h-.1582v-.26953c.69531-.07031 1.23438-.17383 1.61719-.31055h.1289z"/>
        <path fillRule="evenodd" d="M9.5 18c4.6944 0 8.5-3.8056 8.5-8.5C18 4.80558 14.1944 1 9.5 1 4.80558 1 1 4.80558 1 9.5 1 14.1944 4.80558 18 9.5 18zm0 1c5.2467 0 9.5-4.2533 9.5-9.5C19 4.25329 14.7467 0 9.5 0 4.25329 0 0 4.25329 0 9.5 0 14.7467 4.25329 19 9.5 19z" clipRule="evenodd"/>
    </svg>
);

export default TooltipIcon;
