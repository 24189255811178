import classnames from 'classnames';
import { FC } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import ErrorBoundary from '../Errors/ErrorBoundary';
import { CrossIcon } from '../Icons';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

type Props = ReactModalProps & {
    className?: string;
    wrapper?: boolean;
};

const Modal: FC<Props> = ({ className, children, wrapper = true, ...props }) => {
    return (
        <ReactModal
            {...props}
            overlayClassName={classnames('c-dialog', className)}
            className="c-dialog__inner"
            ariaHideApp={false}
            htmlOpenClassName="c-document is-disabled"
            bodyOpenClassName="is-react-modal-open"
        >
            <ErrorBoundary>
                <button
                    type="button"
                    aria-label="Close this dialog"
                    className="c-dialog__close u-btn-unstyled"
                    data-dialog-hide=""
                    onClick={props.onRequestClose}
                >
                    <i className="c-icon c-dialog__icon">
                        <CrossIcon />
                    </i>
                </button>
                {wrapper ? <div className="c-dialog__box">{children}</div> : children}
            </ErrorBoundary>
        </ReactModal>
    );
};
export default Modal;
