import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';
import { Analytics, AnalyticsBrowser, Plugin } from '@segment/analytics-next';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { formatTimeRemaining } from '../components/Timer/Timer';
import { useAppContext } from '../context/AppContext';
import { AuthContextType, useAuth } from '../context/AuthContext';
import { Order, OrderProduct, ProductProperty, ShippingMode, Site, User } from '../types/api';
import { BRANDS, getBrand } from '../utils/brand';
import { LastOrderData, saveLastOrderData } from '../utils/order';
import { StepDetails } from './globalE';
import useCurrency from '../hooks/useCurrency';
import { useCountry } from '../context/CountryContext';

type SegmentEvent = {
    // Event
    event_id: string;
    event?: 'GAevent' | 'Purchase';
    category?: string | null;
    action?: string;
    ecommerce?: object;
    environnement: string;
    platform: string;
    // Site
    site?: string;
    siteBrand?: string;
    siteCurrency?: string;
    langue?: string;
    brand?: string;
    ['type-contenu']?: 'Checkout' | 'Virtual Page View' | 'CheckoutEEC';
    'page-name'?: string;
    breadcrumb?: string;
    // User
    ['statut-connexion']?: 'Logged' | 'Unlogged';
    ['user-id']?: string;
    ['type-client']?: 'New Customer' | 'Repeat Customer' | 'Prospect';
    ['type-visiteur']?: 'Existing Visitor' | 'New Visitor';
    ['adresse-email']?: string;
    ['année-naiss']?: string;
    ['date-1ere-commande']?: string;
    ['date-avant-derniere-commande']?: string;
    ['date-derniere-commande']?: string;
    ['frequence-achat']?: string;
    ['localisation']?: number;
    ['segment-client']?: number;
    subscriber_key?: string | null;
    // Cart
    checkout_id?: string;
    value?: number | string;
    total?: number | string;
    revenue?: number | string;
    subtotal?: number | string;
    shipping?: number | string;
    tax?: number | string;
    'statut-coupon'?: string;
    coupon?: string;
    currency?: string;
    cart_id?: string;
    order_id?: string;
    cartValue?: number | string;
    totebagValue?: number | string;
    timeLeft?: string;
    step?: 1 | 2 | 3;
    affiliation?: string;
    discount?: string;
    payment_method?: string;
    shipping_method?: string;
    // Products
    product_id?: string;
    product_code?: string;
    name?: string;
    sku?: string;
    price?: number | string;
    quantity?: number;
    url?: string;
    variant?: string;
    products?: {
        product_id?: string;
        sku?: string;
        name?: string;
        price?: number | string;
        quantity?: number;
        category?: string;
        productUUID?: string;
        url?: string;
    }[];
    productID?: string | (string | undefined)[];
    productValue?: number | string;
    productEAN?: string | (string | undefined)[];
    productName?: string;
    productCategory?: string;
    productVariant?: string;
    productBrand?: string;
    productQuantity?: string;
    productPrice?: number;
    productCurrency?: string;
    currencyCode?: string;
    content_type?: 'product_group' | 'product';
    content_name?: string | string[];
    content_category?: string | null | (string | undefined | null)[];
    content_ids?: string | number | (string | number | undefined | null)[];
    productUUID?: string | (string | undefined)[];
    color?: string;
    collection?: string;
    fs_color?: string;
    // Account
    newsletterSubscriptionLocation?:
        | 'Footer'
        | 'Optin Creation Compte'
        | 'Compte'
        | 'Optin Authentification Obligatoire'
        | 'Optin CMS Landing'
        | 'Popin';
    // Ecommerce transaction
    transactionID?: string;
    transactionAffiliation?: 'Online Store Sezane' | 'Online Store Octobre';
    transactionRevenue?: number;
    transactionTax?: number;
    transactionShipping?: number;
    transactionCurrency?: 'EUR' | 'USD' | 'GBP';
    transactionDiscount?: number | (number | undefined)[];
    promotionalCampaignCode?: string | (string | undefined)[];
    transactionShippingCity?: string;
    transactionShippingCountry?: string;
    transactionShippingMethod?: string;
    transactionPaymentType?: string;
    shippingCountry?: string;
    shippingCity?: string;
    couponName?: string;
    payement_method?: string;
    // Ecommerce
    shippingMethod?:
        | 'La Conciergerie Paris 2eme'
        | 'La Conciergerie Paris 16eme'
        | 'Point-relais'
        | 'Home Delivery'
        | 'Livraison Paris 2h'
        | 'La Conciergerie Paris 2eme_Livraison Paris 2h'
        | 'La Conciergerie Paris 16eme_Livraison Paris 2h'
        | 'Point-relais_Livraison Paris 2h'
        | 'Home Delivery_Livraison Paris 2h';
    paymentMethod?: 'American Express' | 'MasterCard' | 'VISA' | 'Carte Bancaire' | 'Maestro' | 'Paypal';
    locationInPage?: 'header' | 'body' | 'footer';
    country?: string;
    promoCodeStatus?: 'OK' | 'KO';
    checkoutType?: 'Checkout 2h' | 'Checkout Normal' | 'Checkout Normal_Checkout 2h' | 'Checkout Global-e';
    label?: string;
    isWebview?: boolean;
    fbp?: string;
    fbc?: string;
};

type AnalyticsEvent = {
    // Event
    event?: 'GAevent' | 'Purchase';
    category?:
        | 'removeCart'
        | 'CheckoutEEC'
        | 'Virtual Page View'
        | 'Echec Paiement'
        | 'Change_Country'
        | 'addPromoCode'
        | 'Newsletter_Subscription';
    action?: string;
    ecommerce?: object;
    // Site
    site?: string;
    ['type-contenu']?: 'Checkout';
    breadcrumb?: string;
    // User
    ['statut-connexion']?: 'Logged' | 'Unlogged';
    ['user-id']?: string;
    ['type-client']?: 'New Customer' | 'Repeat Customer' | 'Prospect';
    ['type-visiteur']?: 'Existing Visitor' | 'New Visitor';
    ['adresse-email']?: string;
    ['année-naiss']?: string;
    ['date-1ere-commande']?: string;
    ['date-avant-derniere-commande']?: string;
    ['date-derniere-commande']?: string;
    ['frequence-achat']?: string;
    ['localisation']?: number;
    ['segment-client']?: number;
    // Products
    productID?: string | (string | undefined)[];
    productValue?: number | string;
    productEAN?: string | (string | undefined)[];
    productName?: string;
    productCategory?: string;
    productVariant?: string;
    productBrand?: string;
    productQuantity?: string;
    productPrice?: number;
    productCurrency?: string;
    currencyCode?: string;
    content_type?: 'product_group';
    content_name?: string | string[];
    content_category?: string | (string | undefined)[];
    content_ids?: number | string | (number | string | undefined)[];
    productUUID?: string | (string | undefined)[];
    sizeUUID?: string | (string | undefined)[];
    // Account
    newsletterSubscriptionLocation?:
        | 'Footer'
        | 'Optin Creation Compte'
        | 'Compte'
        | 'Optin Authentification Obligatoire'
        | 'Optin CMS Landing'
        | 'Popin';
    // Ecommerce transaction
    transactionID?: string;
    transactionAffiliation?: 'Online Store Sezane' | 'Online Store Octobre';
    transactionRevenue?: number;
    transactionTax?: number;
    transactionShipping?: number;
    transactionCurrency?: 'EUR' | 'USD' | 'GBP';
    transactionDiscount?: number | (number | undefined)[];
    promotionalCampaignCode?: string | (string | undefined)[];
    transactionShippingCity?: string;
    transactionShippingCountry?: string;
    transactionShippingMethod?: string;
    transactionPaymentType?: string;
    shippingCountry?: string;
    shippingCity?: string;
    couponName?: string;
    // Ecommerce
    shippingMethod?:
        | 'La Conciergerie Paris 2eme'
        | 'La Conciergerie Paris 16eme'
        | 'Point-relais'
        | 'Home Delivery'
        | 'Livraison Paris 2h'
        | 'La Conciergerie Paris 2eme_Livraison Paris 2h'
        | 'La Conciergerie Paris 16eme_Livraison Paris 2h'
        | 'Point-relais_Livraison Paris 2h'
        | 'Home Delivery_Livraison Paris 2h';
    paymentMethod?: 'American Express' | 'MasterCard' | 'VISA' | 'Carte Bancaire' | 'Maestro' | 'Paypal';
    locationInPage?: 'header' | 'body' | 'footer';
    country?: 'FR' | 'UK' | 'US' | 'EU' | 'INT';
    promoCodeStatus?: 'OK' | 'KO';
    checkoutType?: 'Checkout 2h' | 'Checkout Normal' | 'Checkout Normal_Checkout 2h' | 'Checkout Global-e';
    label?: string;
};

let instance: Analytics;

let environment = 'dev';
if (document.location.host.includes('qlf.sezane.dev') || document.location.host.includes('qlf.octobre-editions.dev')) {
    environment = 'qlf';
} else if (document.location.host.includes('sezane.com') || document.location.host.includes('octobre-editions.com')) {
    environment = 'prod';
}

type OneTrustConsent = {
    C0001: boolean;
    C0002: boolean;
    C0003: boolean;
    C0004: boolean;
};

function getOneTrustConsent(): OneTrustConsent {
    const oneTrustConsent = {
        C0001: false,
        C0002: false,
        C0003: false,
        C0004: false,
    };
    const oneTrust = Cookies.get('OptanonConsent')?.split(';')[0];
    const oneTrustData = new URLSearchParams(oneTrust);
    const oneTrustGroups = oneTrustData.get('groups');

    if (oneTrustGroups) {
        oneTrustGroups.split(',').forEach(code => {
            const codeParsed = code.split(':');

            oneTrustConsent[codeParsed[0] as keyof OneTrustConsent] = codeParsed[1] === '1';
        });
    }

    return oneTrustConsent;
}

const SESSION_ID_KEY = 'session_id';

function getSessionId(): string {
    let sessionId = sessionStorage.getItem(SESSION_ID_KEY);

    if (!sessionId) {
        sessionId = uuidv4();
        sessionStorage.setItem('session_id', sessionId);
    }

    return sessionId;
}

export const sessionIdPlugin: Plugin = {
    name: 'Session id plugin',
    type: 'enrichment',
    version: '1.0.0',
    isLoaded: () => true,
    load: () => Promise.resolve(),
    identify: ctx => {
        ctx.updateEvent('properties.session_id', getSessionId());
        return ctx;
    },
    page: ctx => {
        ctx.updateEvent('properties.session_id', getSessionId());
        return ctx;
    },
    track: ctx => {
        ctx.updateEvent('properties.session_id', getSessionId());
        return ctx;
    },
};

const getInstance = async (): Promise<Analytics> => {
    const oneTrustConsent = getOneTrustConsent();

    if (!instance) {
        const [response] = await AnalyticsBrowser.load(
            {
                writeKey:
                    (getBrand() === BRANDS.sezane
                        ? process.env.REACT_APP_SEZANE_SEGMENT_KEY
                        : process.env.REACT_APP_OCTOBRE_SEGMENT_KEY) || '',
                cdnURL:
                    (getBrand() === BRANDS.sezane
                        ? process.env.REACT_APP_SEZANE_SEGMENT_CDN
                        : process.env.REACT_APP_OCTOBRE_SEGMENT_CDN) || undefined,
            },
            {
                integrations: {
                    'Facebook Pixel': oneTrustConsent.C0004,
                    'Google Analytics': oneTrustConsent.C0004,
                    'Pinterest Tag': oneTrustConsent.C0004,
                    'Facebook Pixel Server Side': oneTrustConsent.C0004,
                    'Google AdWords New': oneTrustConsent.C0004,
                    'TikTok Conversions': oneTrustConsent.C0004,
                },
            }
        );
        instance = response;
        instance.register(sessionIdPlugin);
    }

    return instance;
};

const addSegmentEvent = async (name: string, event: SegmentEvent) => {
    if (process.env.NODE_ENV !== 'test') {
        const analytics = await getInstance();
        event.isWebview = false;
        analytics.track(name, event);
    }
};

const identifySegment = async (auth: AuthContextType, additionalData?: Record<string, any>) => {
    if (auth.user) {
        const analytics = await getInstance();
        analytics.identify(auth.keycloakId, {
            'adresse-email': await getSha256(auth.user.email!),
            ...additionalData,
        });
    }
};

const getCheckoutType = (order: Order, isGlobalE?: boolean): AnalyticsEvent['checkoutType'] => {
    if (isGlobalE) {
        return 'Checkout Global-e';
    } else if (order.lastMinuteQuantity! > 0 && order.simpleDeliveryQuantity! > 0) {
        return 'Checkout Normal_Checkout 2h';
    } else if (order.lastMinuteQuantity! > 0) {
        return 'Checkout 2h';
    } else {
        return 'Checkout Normal';
    }
};

const getShippingModeLabel = (shippingMode: ShippingMode) => {
    let label: AnalyticsEvent['shippingMethod'] = shippingMode?.label as AnalyticsEvent['shippingMethod'];

    if (shippingMode?.needsRelay) {
        label = 'Point-relais';
    } else if (shippingMode?.needsAddress) {
        label = 'Home Delivery';
    }

    return label;
};

const getShippingMethod = (order: Order) => {
    let mode = '';

    if (order.shippingMode) {
        mode += getShippingModeLabel(order.shippingMode);
    }

    if (order.lastMinuteShippingMode) {
        mode += mode ? `_Livraison Paris 2h` : `Livraison Paris 2h`;
    }

    return mode;
};

const getPaymentMethodName = (paymentMethod: PaymentMethod, isGlobalE?: boolean): string => {
    // @ts-ignore adyen types are not up to date
    const methodName = isGlobalE ? paymentMethod.name : paymentMethod.brand || paymentMethod.type;
    return methodName === 'scheme' || methodName === 'mc' ? 'Carte bancaire' : methodName;
};

const getBrandName = (brand: string) => brand.toLowerCase();
const getSiteName = (site: Site) => `${site.label?.toLowerCase()}_${site.defaultCurrency?.label}`;

const formatPrice = (price?: number): number | undefined =>
    price !== undefined ? parseFloat((price / 100).toFixed(2)) : undefined;

const getProductValue = (order: Order): number => formatPrice(order.totalPrice ? order.totalPrice : 0)!;

const getClientType = (user: User): AnalyticsEvent['type-client'] => {
    if (user.nbOrders === 0) {
        return 'Prospect';
    } else if (user.nbOrders === 1) {
        return 'New Customer';
    }

    return 'Repeat Customer';
};

async function getSha256(text: string) {
    // From https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
}

type EventId = {
    id: number;
    ts: number;
};
const EVENT_ID_KEY = 'eventIds';
const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;
const formatEventId = (key: string, eventId: EventId) => `${key}.${eventId.id}.${eventId.ts}`;
const getUniqueEventId = (key: string, lifespan = 1000) => {
    const sessionStorageEventIds = sessionStorage.getItem(EVENT_ID_KEY);
    const eventIds: Record<string, EventId> = sessionStorageEventIds ? JSON.parse(sessionStorageEventIds) : {};
    const e = eventIds[key];
    if (e && e.id && e.ts && Date.now() - e.ts < lifespan) {
        return formatEventId(key, e);
    } else {
        const newEventId: EventId = { ts: Date.now(), id: getRandomInt(10000000, 99999999) };
        sessionStorage.setItem(EVENT_ID_KEY, JSON.stringify({ ...eventIds, [key]: newEventId }));
        return formatEventId(key, newEventId);
    }
};

const useAnalytics = () => {
    const auth = useAuth();
    const { site, brand, isGlobalE, orderId } = useAppContext();
    const currency = useCurrency();
    const {
        country: { countryISO },
    } = useCountry();

    const formattedBrand = getBrandName(brand);

    const getProductsForSegment = (order: Order) =>
        order.products!.map(product => ({
            product_id: product.productProperty?.selectedVariant?.selectedItem?.uuid!,
            product_code: product.productProperty?.selectedVariant?.code!,
            sku: product.productProperty?.selectedVariant?.selectedItem?.ean,
            name: product.label,
            price: formatPrice(product.unitPrice),
            quantity: product.quantity,
            category: product.productProperty?.subcategory || '',
            inter_category: product.productProperty?.selectedVariant?.tracking?.categoryReference || '',
            productUUID: product.productProperty?.selectedVariant?.uuid!,
            url: product.productUrl,
            brand: formattedBrand,
            color: product.productProperty?.selectedVariant?.tracking?.variantParentColor,
            collection: product.productProperty?.selectedVariant?.tracking?.collection,
            fs_color: product.productProperty?.selectedVariant?.tracking?.fsColor,
        }));

    const onPageChange = async (order?: Order) => {
        try {
            const analytics = await getInstance();
            analytics.page(document.title, {
                event_id: getUniqueEventId('pageChange'),
                breadcrumb: document.title,
                // navPath: navPath.join('||'), // Can't share sessionStorage with ecommerce
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                country: countryISO,
                environnement: environment,
                platform: 'web',
                'statut-connexion': auth.user ? 'Logged' : 'Unlogged',
                'type-contenu': document.title,
                'user-id': auth.keycloakId ? auth.keycloakId : undefined,
                localisation: order?.addresses?.shipping?.postcode,
                subscriber_key: auth.user?.salesforceId,
                'adresse-email': await getSha256(auth.user?.email!),
                fbp: Cookies.get('_fbp'),
                fbc: Cookies.get('_fbc'),
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onRemoveProduct = (order: Order, product: OrderProduct) => {
        try {
            addSegmentEvent('Product Removed', {
                event_id: getUniqueEventId('productRemoved'),
                product_id: product.productProperty?.selectedVariant?.selectedItem?.uuid!,
                product_code: product.productProperty?.selectedVariant?.code,
                sku: product.productProperty?.selectedVariant?.selectedItem?.ean,
                name: product.label,
                price: formatPrice(product.unitPrice),
                quantity: product.quantity,
                category: product.productProperty?.subcategory,
                productUUID: product.productProperty?.selectedVariant?.uuid!,
                url: product.productUrl,
                brand: formattedBrand,
                country: countryISO,
                variant: product.colorLabel,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                currency: site.defaultCurrency?.code,
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                label: product.label,
                value: formatPrice(product.unitPrice),
                color: product.productProperty?.selectedVariant?.tracking?.variantParentColor,
                collection: product.productProperty?.selectedVariant?.tracking?.collection,
                fs_color: product.productProperty?.selectedVariant?.tracking?.fsColor,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onDisplayCart = async (order: Order) => {
        try {
            addSegmentEvent('Checkout Started', {
                country: countryISO,
                event_id: getUniqueEventId('checkoutStarted'),
                'type-contenu': 'Virtual Page View',
                'page-name': 'Page panier',
                value: getProductValue(order),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                currency: order.currencyCode,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                productValue: getProductValue(order),
                checkoutType: getCheckoutType(order, isGlobalE),
                products: getProductsForSegment(order),
                'adresse-email': await getSha256(auth.user?.email!),
                fbp: Cookies.get('_fbp'),
                fbc: Cookies.get('_fbc'),
            });

            addSegmentEvent('Cart Viewed', {
                event_id: getUniqueEventId('cartViewed'),
                'type-contenu': 'Checkout',
                'page-name': 'Page panier',
                cart_id: orderId,
                content_category: order.products?.map(product => product.productProperty?.subcategory || ''),
                content_ids: order.products?.map(
                    product => product.productProperty?.selectedVariant?.selectedItem?.uuid
                ),
                content_name: order.products?.map(product => product.label!),
                content_type: 'product',
                country: countryISO,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                productValue: getProductValue(order),
                checkoutType: getCheckoutType(order, isGlobalE),
                cartValue: getProductValue(order),
                timeLeft: formatTimeRemaining(new Date(order.expireAt!).getTime() - Date.now()),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                label: getCheckoutType(order, isGlobalE),
                value: getProductValue(order),
                products: getProductsForSegment(order),
                subscriber_key: auth.user?.salesforceId,
            });

            addSegmentEvent('Checkout Step Viewed', {
                event_id: getUniqueEventId('checkoutStep1Viewed'),
                checkout_id: orderId,
                country: countryISO,
                step: 1,
                content_category: order.products?.map(product => product.productProperty?.subcategory || ''),
                content_ids: order.products?.map(
                    product => product.productProperty?.selectedVariant?.selectedItem?.uuid
                ),
                content_name: order.products?.map(product => product.label!),
                content_type: 'product',
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'Virtual Page View',
                'page-name': 'Page panier',
                products: getProductsForSegment(order),
                checkoutType: getCheckoutType(order, isGlobalE),
                subscriber_key: auth.user?.salesforceId,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onShippingDisplay = (order: Order) => {
        try {
            let action = 'Page livraison';

            if (order.lastMinuteQuantity! > 0 && order.simpleDeliveryQuantity! > 0) {
                action = 'Page livraison / Panier mixte';
            } else if (order.lastMinuteQuantity! > 0) {
                action = 'Page livraison / Panier 2h';
            }

            addSegmentEvent('Checkout Step Viewed', {
                event_id: getUniqueEventId('checkoutStep2Viewed'),
                checkout_id: orderId,
                country: countryISO,
                step: 2,
                content_category: order.products?.map(product => product.productProperty?.subcategory || ''),
                content_ids: order.products?.map(
                    product => product.productProperty?.selectedVariant?.selectedItem?.uuid
                ),
                content_name: order.products?.map(product => product.label!),
                content_type: 'product',
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'Virtual Page View',
                'page-name': action,
                products: getProductsForSegment(order),
                checkoutType: getCheckoutType(order, isGlobalE),
                cartValue: getProductValue(order),
                subscriber_key: auth.user?.salesforceId,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onShippingValidate = (order: Order) => {
        try {
        } catch (e) {
            console.error(e);
        }
    };

    const onDisplayPayments = (order: Order) => {
        try {
            addSegmentEvent('Checkout Step Viewed', {
                event_id: getUniqueEventId('checkoutStep3Viewed'),
                checkout_id: orderId,
                country: countryISO,
                step: 3,
                content_category: order.products?.map(product => product.productProperty?.subcategory || ''),
                content_ids: order.products?.map(
                    product => product.productProperty?.selectedVariant?.selectedItem?.uuid
                ),
                content_name: order.products?.map(product => product.label!),
                content_type: 'product',
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                shipping_method: getShippingMethod(order),
                cartValue: getProductValue(order),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'Virtual Page View',
                'page-name': 'Page paiement',
                products: getProductsForSegment(order),
                checkoutType: getCheckoutType(order, isGlobalE),
                subscriber_key: auth.user?.salesforceId,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onPaymentMethodSelect = async (order: Order, paymentMethod: PaymentMethod) => {
        try {
            saveLastOrderData(order, paymentMethod);
            addSegmentEvent('Checkout Step Completed', {
                event_id: getUniqueEventId('checkoutStep3Completed'),
                checkout_id: orderId,
                country: countryISO,
                step: 3,
                shipping_method: getShippingMethod(order),
                payment_method: getPaymentMethodName(paymentMethod),
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                cartValue: getProductValue(order),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'CheckoutEEC',
                'page-name': 'Payment Method',
                checkoutType: getCheckoutType(order, isGlobalE),
            });
            addSegmentEvent('Payment Info Entered', {
                event_id: getUniqueEventId('paymentInfoEntered'),
                checkout_id: orderId,
                country: countryISO,
                step: 3,
                shipping_method: getShippingMethod(order),
                payment_method: getPaymentMethodName(paymentMethod),
                content_category: order.products?.map(product => product.productProperty?.subcategory),
                content_ids: order.products?.map(
                    product => product.productProperty?.selectedVariant?.selectedItem?.uuid
                ),
                content_name: order.products?.map(product => product.label!),
                content_type: 'product',
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                currency: site.defaultCurrency?.code,
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                cartValue: getProductValue(order),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'CheckoutEEC',
                'page-name': 'Payment Method',
                checkoutType: getCheckoutType(order, isGlobalE),
                'adresse-email': await getSha256(auth.user?.email!),
                fbp: Cookies.get('_fbp'),
                fbc: Cookies.get('_fbc'),
            });
        } catch (e) {
            console.error(e);
        }
    };

    type PaymentResponse = {
        merchantReference: string;
        amount?: {
            currency: string;
            value: number;
        };
    };

    const onPaymentSuccess = async (lastOrderData: LastOrderData, adyenResponse?: PaymentResponse) => {
        try {
            const order = lastOrderData.order;
            const shippingMethod = getShippingMethod(order);
            const shippingCountry = [order.addresses?.shipping?.country, order.addresses?.lastMinuteShipping?.country]
                .filter(Boolean)
                .join('_');
            const shippingCity = [order.addresses?.shipping?.city, order.addresses?.lastMinuteShipping?.city]
                .filter(Boolean)
                .join('_');
            addSegmentEvent('Order Completed', {
                event_id: getUniqueEventId('orderCompleted'),
                checkout_id: orderId,
                country: countryISO,
                order_id: adyenResponse ? adyenResponse?.merchantReference : order.uuid,
                affiliation: `Online Store ${brand}`,
                total: formatPrice(order.totalPrice!),
                revenue: formatPrice(order.totalPrice! - order.totalVat!),
                subtotal: formatPrice(order.subTotalPrice!),
                shipping: formatPrice(order.shippingPrice!),
                tax: formatPrice(order.totalVat!),
                discount: order.vouchers?.map(voucher => formatPrice(voucher.price)).join(','),
                content_category: order.products?.map(product => product.productProperty?.subcategory || ''),
                content_ids: order.products?.map(
                    product => product.productProperty?.selectedVariant?.selectedItem?.uuid
                ),
                content_name: order.products?.map(product => product.label!),
                content_type: 'product',
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                currency: order.currencyCode,
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'CheckoutEEC',
                'page-name': 'Purchase',
                products: getProductsForSegment(order),
                checkoutType: getCheckoutType(order, isGlobalE),
                productValue: getProductValue(order),
                transactionPaymentType: getPaymentMethodName(lastOrderData.paymentMethod, isGlobalE),
                promotionalCampaignCode: order.vouchers?.map(voucher => voucher.code),
                transactionShippingMethod: shippingMethod,
                transactionShippingCountry: shippingCountry,
                transactionShippingCity: shippingCity,
                subscriber_key: auth.user?.salesforceId,
                'adresse-email': await getSha256(auth.user?.email!),
                fbp: Cookies.get('_fbp'),
                fbc: Cookies.get('_fbc'),
                'type-client': auth.user
                    ? getClientType({ ...auth.user, nbOrders: (auth.user.nbOrders || 0) + 1 })
                    : undefined,
            });

            identifySegment(auth, { location: order.addresses?.shipping?.postcode });
        } catch (e) {
            console.error(e);
        }
    };

    const onPaymentFailed = ({ order, paymentMethod }: LastOrderData) => {
        try {
            addSegmentEvent('Failed Payment', {
                country: countryISO,
                event_id: getUniqueEventId('failedPayment'),
                payement_method: getPaymentMethodName(paymentMethod),
                value: formatPrice(order.totalPrice),
                products: getProductsForSegment(order),
                label: getPaymentMethodName(paymentMethod),
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onPromoCode = (code: string, success: boolean) => {
        try {
            addSegmentEvent('addPromoCode', {
                event_id: getUniqueEventId('addPromoCode'),
                coupon: code,
                country: countryISO,
                'statut-coupon': success ? 'OK' : 'KO',
                label: code,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onChangeCountry = (country: string) => {
        try {
        } catch (e) {
            console.error(e);
        }
    };

    const onNewsletterSubscription = (action: string) => {
        try {
        } catch (e) {
            console.error(e);
        }
    };

    const onGlobalELoaded = (order: Order) => {
        onShippingDisplay(order);
        onDisplayPayments(order);
    };

    const onGlobalEConfirmation = (order: Order, details: StepDetails) => {
        try {
        } catch (e) {
            console.error(e);
        }
    };

    const onCheckoutCompleted = (order: Order) => {
        try {
            addSegmentEvent('Checkout Step Completed', {
                event_id: getUniqueEventId('checkoutStep1Completed'),
                checkout_id: orderId,
                country: countryISO,
                step: 1,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                cartValue: getProductValue(order),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'Virtual Page View',
                'page-name': 'Page panier',
                checkoutType: getCheckoutType(order, isGlobalE),
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onShippingCompleted = (order: Order) => {
        try {
            addSegmentEvent('Checkout Step Completed', {
                event_id: getUniqueEventId('checkoutStep2Completed'),
                checkout_id: orderId,
                country: countryISO,
                step: 2,
                shipping_method: getShippingMethod(order),
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                cartValue: getProductValue(order),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                'type-contenu': 'CheckoutEEC',
                'page-name': 'Shipping Method',
                checkoutType: getCheckoutType(order, isGlobalE),
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onPromotionalCampaignAdded = (order: Order, productProperty: ProductProperty) => {
        try {
            addSegmentEvent('Totebag Added', {
                event_id: getUniqueEventId('promotionalCampaignAdded'),
                cartValue: getProductValue(order),
                country: countryISO,
                totebagValue: productProperty.selectedVariant?.selectedItem?.size,
                value: productProperty.selectedVariant?.selectedItem?.size,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onQuantityAdded = (order: Order, product: OrderProduct) => {
        try {
            addSegmentEvent('Quantity Added', {
                event_id: getUniqueEventId('quantityAdded'),
                product_id: product.productProperty?.selectedVariant?.selectedItem?.uuid!,
                product_code: product.productProperty?.selectedVariant?.code,
                country: countryISO,
                sku: product.productProperty?.selectedVariant?.selectedItem?.ean,
                name: product.label,
                price: formatPrice(product.unitPrice),
                category: product.productProperty?.subcategory,
                productUUID: product.productProperty?.selectedVariant?.uuid!,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                label: product.label,
                value: formatPrice(product.unitPrice),
                cartValue: getProductValue(order),
                color: product.productProperty?.selectedVariant?.tracking?.variantParentColor,
                collection: product.productProperty?.selectedVariant?.tracking?.collection,
                fs_color: product.productProperty?.selectedVariant?.tracking?.fsColor,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onQuantityRemoved = (order: Order, product: OrderProduct) => {
        try {
            addSegmentEvent('Quantity Removed', {
                event_id: getUniqueEventId('quantityRemoved'),
                product_id: product.productProperty?.selectedVariant?.selectedItem?.uuid!,
                product_code: product.productProperty?.selectedVariant?.code,
                country: countryISO,
                sku: product.productProperty?.selectedVariant?.selectedItem?.ean,
                name: product.label,
                price: formatPrice(product.unitPrice),
                quantity: product.quantity,
                category: product.productProperty?.subcategory,
                productUUID: product.productProperty?.selectedVariant?.uuid!,
                url: product.productUrl,
                variant: product.colorLabel,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                label: product.label,
                value: formatPrice(product.unitPrice),
                cartValue: getProductValue(order),
                color: product.productProperty?.selectedVariant?.tracking?.variantParentColor,
                collection: product.productProperty?.selectedVariant?.tracking?.collection,
                fs_color: product.productProperty?.selectedVariant?.tracking?.fsColor,
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onRelaySelect = () => {
        try {
            addSegmentEvent('Click Point-relais selection', {
                event_id: getUniqueEventId('clickRelaysSelection'),
                country: countryISO,
                'type-contenu': 'Checkout',
                'page-name': document.title,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
            });
        } catch (e) {
            console.error(e);
        }
    };

    const onGiftClick = (order: Order) => {
        try {
            addSegmentEvent('Gift click', {
                event_id: getUniqueEventId('clickGift'),
                'type-contenu': 'Checkout',
                'page-name': document.title,
                country: countryISO,
                cart_id: orderId,
                site: getSiteName(site),
                siteBrand: formattedBrand,
                siteCurrency: currency?.code?.toUpperCase(),
                langue: site.defaultLocale?.code,
                environnement: environment,
                platform: 'web',
                checkoutType: getCheckoutType(order),
                cartValue: getProductValue(order),
                coupon: order.vouchers?.map(campaign => campaign.code).join(','),
                label: getCheckoutType(order),
                value: getProductValue(order),
                products: getProductsForSegment(order),
            });
        } catch (e) {
            console.error(e);
        }
    };

    return {
        onPageChange,
        onRemoveProduct,
        onDisplayCart,
        onShippingDisplay,
        onShippingValidate,
        onPaymentMethodSelect,
        onDisplayPayments,
        onPaymentSuccess,
        onPaymentFailed,
        onPromoCode,
        onChangeCountry,
        onNewsletterSubscription,
        onGlobalELoaded,
        onGlobalEConfirmation,
        onCheckoutCompleted,
        onShippingCompleted,
        onPromotionalCampaignAdded,
        onQuantityAdded,
        onQuantityRemoved,
        onRelaySelect,
        onGiftClick,
    };
};

export default useAnalytics;
