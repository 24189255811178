import React, { useContext, useEffect, useState } from 'react';
import { useAppContext } from './AppContext';

type GlobalEContextType = {
    isLoaded: boolean;
};

const GlobalEContext = React.createContext<GlobalEContextType>({
    isLoaded: false,
});

export const GlobalEProvider: React.FC<{}> = ({ children }) => {
    const { isGlobalE } = useAppContext();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isGlobalE) return;

        const script = document.createElement('script');
        script.src = process.env.REACT_APP_GLOBAL_E_JS_URL || '';
        script.async = true;
        script.onload = () => {
            setIsLoaded(true);
        };
        document.body.appendChild(script);

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = process.env.REACT_APP_GLOBAL_E_CSS_URL || '';
        document.body.appendChild(link);

        return () => {
            document.body.removeChild(script);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <GlobalEContext.Provider value={{ isLoaded }}>{children}</GlobalEContext.Provider>;
};

export const useGlobalE = () => {
    const globalEContext = useContext(GlobalEContext);
    return globalEContext;
};
