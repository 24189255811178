import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

const onError = (error: Error) => {
    if (window.DD_RUM && window.DD_RUM.addError) {
        window.DD_RUM.addError(error);
    }
};

const ErrorBoundary: React.FC = ({ children }) => {
    return (
        <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
            {children ? children : null}
        </ReactErrorBoundary>
    );
};

export default ErrorBoundary;
