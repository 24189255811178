import { GeolocDialog, GeolocDialogProps, getGlobalECountryCookie } from '@sezane/front-components';
import React, { useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import ErrorBoundary from '../components/Errors/ErrorBoundary';
import { getRedirectUrl } from '../hooks/useUrl';
import { useAppContext } from './AppContext';
import { useCountry } from './CountryContext';
import { useOrderContext } from './OrderContext';

type GeolocContextType = {
    openGeolocDialog: (manual?: boolean) => void;
};

const GeolocContext = React.createContext<GeolocContextType>({} as GeolocContextType);

export const GeolocProvider: React.FC<{}> = ({ children }) => {
    const intl = useIntl();
    const { site, sites, mappingCountrySite, locale, orderId } = useAppContext();
    const { updateOrder } = useOrderContext();
    const { country, setCountry } = useCountry();

    const initialShouldDisplayGeolocDialog = site.code !== mappingCountrySite[country.countryISO!];
    const [displayGeolocDialog, setDisplayGeolocDialog] = useState(initialShouldDisplayGeolocDialog);
    const [manual, setManual] = useState(false);
    const openGeolocDialog = (manualValue = false) => {
        setManual(manualValue);
        setDisplayGeolocDialog(true);
    };

    const countries: GeolocDialogProps['countries'] = useMemo(() => {
        return Object.entries(mappingCountrySite).map(([countryCode, siteCode]) => ({
            code: countryCode,
            locales:
                // @ts-expect-error
                sites
                    .find(site => site?.code === siteCode)
                    .availableLocales?.map(availableLocale => availableLocale.code!) || [],
            defaultLocale: sites.find(site => site?.code === siteCode)?.defaultLocale?.code!,
        }));
    }, [mappingCountrySite, sites]);

    const onGeolocChoice: GeolocDialogProps['onSubmit'] = async (country, language) => {
        const selectedSite = country ? sites.find(site => site.code === mappingCountrySite[country]) : site;
        const shippingCountry = selectedSite?.shippingCountries?.find(({ code }) => code === country)!;
        const globalEData = getGlobalECountryCookie();
        setCountry({
            ...globalEData,
            countryISO: country,
            currencyCode: shippingCountry?.outputCurrencyCode ?? shippingCountry.currencyCode,
            regionCode: country === globalEData.countryISO ? globalEData.regionCode : undefined,
            locale: language,
        });
        orderId &&
            updateOrder
                .mutateAsync({
                    site: selectedSite?.code,
                    currencyCode: shippingCountry?.currencyCode,
                    country,
                    // @ts-expect-error
                    locale: language,
                })
                .then(() => {
                    window.location.href = getRedirectUrl(selectedSite!, '/', undefined, language);
                });
        setDisplayGeolocDialog(false);
    };

    const onClose = async () => {
        setDisplayGeolocDialog(false);

        if (manual) return;

        const globalEData = getGlobalECountryCookie();
        const newCountry = {
            ...globalEData,
            countryISO: site.defaultCountryCode,
            currencyCode: site.defaultCurrency?.code,
            locale,
        };
        delete newCountry.regionCode;

        setCountry(newCountry);
        orderId &&
            updateOrder.mutateAsync({
                site: site.code,
                currencyCode: site.defaultCurrency?.code,
                country: site.defaultCountryCode,
                // @ts-expect-error
                locale: site.defaultLocale?.code,
            });
    };

    return (
        <GeolocContext.Provider value={{ openGeolocDialog }}>
            {children}
            <ErrorBoundary>
                {displayGeolocDialog && (
                    <GeolocDialog
                        open={true}
                        onClose={onClose}
                        countries={countries}
                        initialCountry={country.countryISO?.toUpperCase() as Uppercase<string>}
                        translations={{
                            title: intl.formatMessage({ id: 'geoloc_dialog.title' }),
                            subtitle: intl.formatMessage({ id: 'geoloc_dialog.subtitle' }),
                            selectCountry: intl.formatMessage({ id: 'geoloc_dialog.select_country' }),
                            selectLanguage: intl.formatMessage({ id: 'geoloc_dialog.select_language' }),
                            button: intl.formatMessage({ id: 'geoloc_dialog.button' }),
                            footer: intl.formatMessage({ id: 'geoloc_dialog.footer' }),
                        }}
                        onSubmit={onGeolocChoice}
                    />
                )}
            </ErrorBoundary>
        </GeolocContext.Provider>
    );
};

export const useGeolocContext = () => {
    const geolocContext = useContext(GeolocContext);
    return geolocContext;
};
